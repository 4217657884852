import MyCard from "@common-utils/MyCard"
import React from "react"
import MyAlert from "@common-utils/MyAlert"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { getAlertesCounterDashboardRequest } from "../core/_requests"
import { IGetAlertesCounterDashboardResponseModel } from "../core/_models"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import {
     ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_CONSULTANTS_AVEC_DOCS_EMBAUCHE_NON_ENVOYES,
     ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION,
     ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE,
     ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION,
     ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION,
} from "../routing/routing-endpoints"
import { useAuth } from "../../../AppContext"

const REACT_QUERY_KEY_GET_ALERTES_COUNTER = "REACT_QUERY_KEY_GET_ALERTES_COUNTER"
const DashboardAlertes = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()

     const alertesCounterQuery = useQuery<IGetAlertesCounterDashboardResponseModel, AxiosError>(REACT_QUERY_KEY_GET_ALERTES_COUNTER, async () =>
          getAlertesCounterDashboardRequest({
               dateConsultantsMissionsExpiredOuArrivantToExpiration: moment().add(15, "days").format("YYYY-MM-DD"),
               dateConsultantsPeriodesEssaiExpiredOuArrivantToExpiration: moment().add(30, "days").format("YYYY-MM-DD"),
               dateClientPropalsExpiredOuArrivantToExpiration: moment().add(15, "days").format("YYYY-MM-DD"),
          }).then(r => ({
               ...r.data,
               alertesSociales:
                    r.data.consultantsPeriodesEssaiExpiredOuArrivantToExpiration +
                    r.data.consultantsAvecContratOuAvenantNonSignes +
                    r.data.ddcProductionEnCours +
                    r.data.ddcStructureEnCours,
               alertesCommerciales:
                    r.data.consultantsAvecDocsEmbaucheNonEnvoyes +
                    r.data.consultantsMissionsExpiredOuArrivantToExpiration +
                    r.data.bonsCommandesManquants +
                    r.data.clientPropalsExpiredOuArrivantToExpiration +
                    r.data.consultantsOrdresMissionExpiredOuArrivantToExpiration,
          }))
     )

     const isAlertSocialeDdcProductionVisible = (() => {
          return (
               ["ROLE_GROUP_ADMINISTRATIF", "ROLE_GROUP_IA", "ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) ||
               currentUser!.consultantsSousGestion.filter(item => ["DROIT_CONSULTATION_DDC", "DROIT_VALIDATION_DDC", "DROIT_REJET_DDC"].includes(item.type) && item.total > 0)
                    .length > 0
          )
     })()

     const isAlertSocialeDdcStructureVisible = (() => {
          return ["ROLE_GROUP_DIRECTION", "ROLE_GROUP_APP_ADMIN"].includes(currentUser!.roleGroup as string) || currentUser!.personnelHierarchyFromUpToDown!.length > 0
     })()

     return (
          <>
               <MyCard classNames={"mb-8"}>
                    <MyCard.Header classNames={`bg-danger`}>
                         <div className={`text-white fw-bold d-flex align-items-center`}>
                              <span className={`fas fa-exclamation-triangle me-2 fs-2`} /> MES ALERTES
                         </div>
                    </MyCard.Header>
                    <MyCard.Body>
                         {alertesCounterQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {!alertesCounterQuery.isFetching && alertesCounterQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{alertesCounterQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => alertesCounterQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {!alertesCounterQuery.isFetching && !alertesCounterQuery.isError && alertesCounterQuery.data && (
                              <div className="row">
                                   <div className={`p-4 col-sm-6 border border-dashed text-center mb-4 mb-sm-0`}>
                                        <h4 className={`mb-4 text-${alertesCounterQuery.data.alertesSociales === 0 ? "success" : "danger"}`}>SOCIALES</h4>
                                        <div className={`separator separator-dashed border-${alertesCounterQuery.data.alertesSociales === 0 ? "success" : "danger"} mb-6`} />
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.consultantsPeriodesEssaiExpiredOuArrivantToExpiration > 0 ? "danger" : "success"
                                             } fs-5 mb-3 d-block`}
                                             onClick={() => navigate(ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_PE_EXPIRATION)}
                                        >
                                             {alertesCounterQuery.data.consultantsPeriodesEssaiExpiredOuArrivantToExpiration} périodes d'essai{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.consultantsPeriodesEssaiExpiredOuArrivantToExpiration > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                        <div className={"btn btn-sm btn-light fs-5 d-block mb-3"}>Entretien de Fin d'Année (soon ...)</div>

                                        {(isAlertSocialeDdcProductionVisible || isAlertSocialeDdcStructureVisible) && (
                                             <div className="row">
                                                  {isAlertSocialeDdcProductionVisible && (
                                                       <div className="col-sm">
                                                            <div
                                                                 className={`btn btn-sm btn-light-${
                                                                      alertesCounterQuery.data.ddcProductionEnCours > 0 ? "danger" : "success"
                                                                 } fs-5 mb-3 d-block`}
                                                                 onClick={() =>
                                                                      navigate(ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_PRODUCTION, { state: { etat: "ETAT_EN_COURS" } })
                                                                 }
                                                            >
                                                                 {alertesCounterQuery.data.ddcProductionEnCours} DDC ( production ){" "}
                                                                 <i
                                                                      className={`fas fa-${
                                                                           alertesCounterQuery.data.ddcProductionEnCours > 0 ? "exclamation-triangle" : "check-circle"
                                                                      }`}
                                                                 />
                                                            </div>
                                                       </div>
                                                  )}
                                                  {isAlertSocialeDdcStructureVisible && (
                                                       <div className="col-sm">
                                                            <div
                                                                 className={`btn btn-sm btn-light-${
                                                                      alertesCounterQuery.data.ddcStructureEnCours > 0 ? "danger" : "success"
                                                                 } fs-5 mb-3 d-block`}
                                                                 onClick={() =>
                                                                      alertesCounterQuery.data.ddcStructureEnCours > 0 &&
                                                                      navigate(ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_DDC_STRUCTURE, { state: { etat: "ETAT_EN_COURS" } })
                                                                 }
                                                            >
                                                                 {alertesCounterQuery.data.ddcStructureEnCours} DDC ( structure ){" "}
                                                                 <i
                                                                      className={`fas fa-${
                                                                           alertesCounterQuery.data.ddcStructureEnCours > 0 ? "exclamation-triangle" : "check-circle"
                                                                      }`}
                                                                 />
                                                            </div>
                                                       </div>
                                                  )}
                                             </div>
                                        )}
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.consultantsAvecContratOuAvenantNonSignes > 0 ? "danger" : "success"
                                             } fs-5 mb-3 d-block`}
                                             onClick={() => toast.info("Maintenance en cours ...")}
                                        >
                                             {alertesCounterQuery.data.consultantsAvecContratOuAvenantNonSignes} contrats à signer{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.consultantsAvecContratOuAvenantNonSignes > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                        <div className={"btn btn-sm btn-light fs-5 d-block"}>Anniversaires (soon ...)</div>
                                   </div>

                                   <div className={`p-4 col-sm-6 border border-dashed text-center`}>
                                        <h4 className={`mb-4 text-${alertesCounterQuery.data.alertesCommerciales === 0 ? "success" : "danger"}`}>COMMERCIALES</h4>
                                        <div className={`separator separator-dashed border-${alertesCounterQuery.data.alertesCommerciales === 0 ? "success" : "danger"} mb-6`} />
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.consultantsAvecDocsEmbaucheNonEnvoyes > 0 ? "danger" : "success"
                                             } fs-5 mb-3 d-block`}
                                             onClick={() => navigate(ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_CONSULTANTS_AVEC_DOCS_EMBAUCHE_NON_ENVOYES)}
                                        >
                                             {alertesCounterQuery.data.consultantsAvecDocsEmbaucheNonEnvoyes} documents d'embauche à envoyer{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.consultantsAvecDocsEmbaucheNonEnvoyes > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.consultantsMissionsExpiredOuArrivantToExpiration > 0 ? "danger" : "success"
                                             } fs-5 mb-3 d-block`}
                                             onClick={() => navigate(ROUTING_ABSOLUTE_PATH_DASHBOARD_ALERTES_MISSION_EXPIRATION)}
                                        >
                                             {alertesCounterQuery.data.consultantsMissionsExpiredOuArrivantToExpiration} ODM / Mission à renouveler{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.consultantsMissionsExpiredOuArrivantToExpiration > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                        <div
                                             className={`btn btn-sm btn-light-${alertesCounterQuery.data.bonsCommandesManquants > 0 ? "danger" : "success"} fs-5 mb-3 d-block`}
                                             onClick={() => toast.info("Maintenance en cours ...")}
                                        >
                                             {alertesCounterQuery.data.bonsCommandesManquants} bons de commande manquant{" "}
                                             <i className={`fas fa-${alertesCounterQuery.data.bonsCommandesManquants > 0 ? "exclamation-triangle" : "check-circle"}`} />
                                        </div>
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.clientPropalsExpiredOuArrivantToExpiration > 0 ? "danger" : "success"
                                             } fs-5 mb-3 d-block`}
                                             onClick={() => toast.info("Maintenance en cours ...")}
                                        >
                                             {alertesCounterQuery.data.clientPropalsExpiredOuArrivantToExpiration} PTF à prolonger{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.clientPropalsExpiredOuArrivantToExpiration > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                        <div
                                             className={`btn btn-sm btn-light-${
                                                  alertesCounterQuery.data.consultantsOrdresMissionExpiredOuArrivantToExpiration > 0 ? "danger" : "success"
                                             } fs-5 d-block`}
                                             onClick={() => toast.info("Maintenance en cours ...")}
                                        >
                                             {alertesCounterQuery.data.consultantsOrdresMissionExpiredOuArrivantToExpiration} ODM à signer{" "}
                                             <i
                                                  className={`fas fa-${
                                                       alertesCounterQuery.data.consultantsOrdresMissionExpiredOuArrivantToExpiration > 0 ? "exclamation-triangle" : "check-circle"
                                                  }`}
                                             />
                                        </div>
                                   </div>
                              </div>
                         )}
                    </MyCard.Body>
               </MyCard>
          </>
     )
}

export default DashboardAlertes
